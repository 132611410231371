import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCzBjxBAt3_uKGg7RqnojXqwowY8I1UWFw',
  authDomain: 'mindsapphire.firebaseapp.com',
  projectId: 'mindsapphire',
  storageBucket: 'mindsapphire.appspot.com',
  messagingSenderId: '1094527035422',
  appId: '1:1094527035422:web:d9e9f5e1ce19817042260f',
}

export default firebaseConfig